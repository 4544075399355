<template>
  <b-navbar toggleable="lg" type="dark" variant="primary">
    <b-container>
      <b-navbar-brand href="https://baozi.100k.hk/" target="_blank">包子老師</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#" :active="active_id === 0" @click="switchPage(0)">查看成績</b-nav-item>
          <b-nav-item href="#" :active="active_id === 1" @click="switchPage(1)">查看試卷</b-nav-item>
          <b-nav-item href="https://baozi.100k.hk/" target="_blank">主站</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  name: "Navbar",
  props: {
    active_id: Number
  },
  methods: {
    switchPage(id) {
      this.$emit('switch-page', id)
    }
  }
}
</script>

<style scoped>

</style>