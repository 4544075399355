<template>
  <b-form
      class="mt-3"
      @submit.prevent="query">
    <b-form-group
        label="輸入你B站的UID，查看自己的成績。"
        label-for="bilibili-uid">
      <b-form-input
          id="bilibili-uid"
          placeholder="B站UID"
          @focus="unknown_uid=''"
          v-model="query_uid"></b-form-input>
    </b-form-group>
    <b-button type="submit" variant="primary">查詢</b-button>
    <b-button type="reset" variant="danger">重置</b-button>
    <b-alert :show="!!unknown_uid" dismissible variant="warning" class="mt-2">UID {{ unknown_uid }} 不存在</b-alert>
    <b-table striped bordered hover class="mt-2" :items="items" :fields="fields" v-show="items.length > 0">
      <template #cell(result_answer)="data">
        <b-list-group>
          <template v-for="(ans, index) in data.item.answer">
            <b-list-group-item variant="success" v-if="ans" :key="index">
              第{{ index + 1 }}題
              <strong v-if="ans" class="text-success">正確</strong>
            </b-list-group-item>
            <b-list-group-item variant="danger" v-if="!ans" :key="index">
              第{{ index + 1 }}題
              <strong v-if="!ans" class="text-danger">錯誤</strong>
            </b-list-group-item>
          </template>
        </b-list-group>
      </template>
    </b-table>
  </b-form>
</template>

<script>
import axios from "axios";

export default {
  name: "ResultChecker",
  data() {
    return {
      fields: [
        {
          key: 'quiz_id',
          label: '試卷編號',
        },
        {
          key: 'username',
          label: '用戶名'
        },
        {
          key: 'time',
          label: '答題時間'
        },
        {
          key: 'result',
          label: '分數'
        },
        {
          key: 'result_answer',
          label: '結果'
        }
      ],
      items: [],
      query_uid: '',
      unknown_uid: ''
    }
  },
  methods: {
    query() {
      let url
      if (process.env.NODE_ENV === 'development') {
        url = process.env.BASE_URL + 'test/get_result_test.json'
      } else {
        url = '/api/get_result.php?uid=' + this.query_uid
      }
      axios.get(url).then(response => {
        if (response.data.error !== undefined) {
          this.$emit('error', response.data.error)
          console.log(response.data)
          return
        }
        if (response.data.exist === true) {
          this.items = response.data.data
        } else {
          this.items = []
          this.unknown_uid = this.query_uid
        }
      })
    }
  }
}
</script>

<style scoped>

</style>