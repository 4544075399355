<template>
  <div>
    <div class="mt-3">答題請進入：<a href="https://live.bilibili.com/22690525" target="_blank" rel="noopener noreferrer">包子老師的直播間</a></div>
    <b-form inline @submit.prevent="query" class="mt-3">
      <b-form-input id="id" placeholder="試卷編號" class="mr-2" @focus="unknown_id=''" v-model="query_id"></b-form-input>
      <b-button variant="primary" type="submit">查看</b-button>
    </b-form>
    <b-alert :show="!!unknown_id" dismissible variant="warning" class="mt-2">試卷編號 {{ unknown_id }} 不存在</b-alert>

    <div v-for="(q, index) in items" v-bind:key="index" class="mt-3">
      <p>第{{ index + 1 }}題：{{ q.question }}</p>
      <ol style="list-style-type: upper-alpha">
        <li>{{ q.answer_A }}</li>
        <li>{{ q.answer_B }}</li>
        <li>{{ q.answer_C }}</li>
        <li>{{ q.answer_D }}</li>
      </ol>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "QuizQuestions",
  data() {
    return {
      items: [],
      query_id: '',
      unknown_id: ''
    }
  },
  methods: {
    query() {
      let url
      if (process.env.NODE_ENV === 'development') {
        url = process.env.BASE_URL + 'test/get_quiz_questions_test.json'
      } else {
        url = '/api/get_quiz_questions.php?id=' + this.query_id
      }
      axios.get(url).then(response => {
        if (response.data.error !== undefined) {
          this.$emit('error', response.data.error)
          console.log(response.data)
          return
        }
        if (response.data.exist === true) {
          this.items = response.data.data
        } else {
          this.items = []
          this.unknown_id = this.query_id
        }
      })
    }
  }
}
</script>

<style scoped>

</style>