<template>
  <div class="mt-3">
    <h2>最新答題紀錄</h2>
    <b-table striped bordered hover :items="items" :fields="fields">
      <template #cell(result_result)="data">
        <span v-if="data.item.result === null">隱蔵中</span>
        <span v-if="data.item.result !== null">{{ data.item.result }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LatestRecord",
  data() {
    return {
      fields: [
        {
          key: 'username',
          label: '用戶名'
        },
        {
          key: 'quiz_id',
          label: '試卷編號'
        },
        {
          key: 'timestamp',
          label: '答題時間'
        },
        {
          key: 'result_result',
          label: '分數'
        }
      ],
      items: []
    }
  },
  mounted() {
    let url
    if (process.env.NODE_ENV === 'development') {
      url = process.env.BASE_URL + 'test/latest_record_test.json'
    } else {
      url = '/api/latest_record.php'
    }
    axios.get(url).then(response => {
      if (response.data.error !== undefined) {
        this.$emit('error', response.data.error)
        console.log(response.data)
        return
      }
      this.items = response.data
    })
  }
}
</script>

<style scoped>

</style>