<template>
  <div>
    <ResultChecker @error="(err) => this.$emit('error', err)"/>
    <LatestRecord @error="(err) => this.$emit('error', err)"/>
    <Ranking @error="(err) => this.$emit('error', err)"/>
  </div>
</template>

<script>
import Ranking from "@/components/Ranking";
import LatestRecord from "@/components/LatestRecord";
import ResultChecker from "@/components/ResultChecker";

export default {
  name: "Quiz",
  components: {Ranking, LatestRecord, ResultChecker},
}
</script>

<style scoped>

</style>