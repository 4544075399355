<template>
  <div id="app">
    <Navbar :active_id="page_id" @switch-page="switchPage"/>
    <b-container>
      <b-alert dismissible variant="danger" class="mt-2" :show="!!error">{{ error }}</b-alert>
      <Quiz v-if="page_id === 0" @error="on_error"/>
      <QuizQuestions v-if="page_id === 1"/>
    </b-container>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Quiz from "@/components/Quiz";
import QuizQuestions from "@/components/QuizQuestions";

export default {
  name: 'App',
  components: {QuizQuestions, Quiz, Navbar},
  data() {
    return {
      error: '',
      page_id: 0
    }
  },
  methods: {
    switchPage(id) {
      this.page_id = id
    },
    on_error(err) {
      this.error = err
    }
  }
}
</script>

<style>
</style>
