<template>
  <div class="mt-3">
    <h2>答題狀元榜</h2>
    <b-table striped bordered hover :items="items" :fields="fields">
      <template #cell(result_username)="data">
        <span v-if="data.item.username === null">隱蔵中</span>
        <span v-if="data.item.username !== null">{{ data.item.username }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Ranking",
  data() {
    return {
      fields: [
        {
          key: 'result_username',
          label: '用戶名'
        },
        {
          key: 'result',
          label: '總分數'
        }
      ],
      items: []
    }
  },
  mounted() {
    let url
    if (process.env.NODE_ENV === 'development') {
      url = process.env.BASE_URL + 'test/ranking_test.json'
    } else {
      url = '/api/ranking.php'
    }
    axios.get(url).then(response => {
      if (response.data.error !== undefined) {
        this.$emit('error', response.data.error)
        console.log(response.data)
        return
      }
      this.items = response.data
    })
  }
}
</script>

<style scoped>

</style>